.features-tiles-item-image {
	display: inline-flex;
	border-radius: 50%;
	background-color: #ffed00;
}
p > img{
	background-color: #ffed00;
	border-radius: 50%;
    min-height: 34px!important;
    min-width: 34px!important;
    max-height: 34px !important;
    max-width: 34px !important;

}
#titulo-sideSheet{
	
	display: inline-flex;

	color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.1px;
    margin-bottom: 20px;
}
#botonPadre{
	
	display: flex;
    justify-content: center;
	color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.1px;
    margin-bottom: 20px;
}
#titulo-sideSheet > img{
	margin-right: 20px;
}

#parrafo-sideSheet{
	color: rgb(156, 169, 179);
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.1px;
    margin-top: -10px;
	margin-bottom: -10px;
	padding-left: 53px;
}
.ub-w_620px{
    max-width: 86vw ;
	width: 470px !important ;
    
}
#tabla{
    max-width: 70vw;
    margin: auto !important;
}
#informacion{
    margin-top: 10%;
    display: inline-flex;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: inherit;
    font-weight: 600;
    padding: 11px 31px;
    height: 40px;
    color: rgb(156, 169, 179);
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    transition: background 0.15s ease 0s;
    background-color: rgb(37, 40, 44);
}
#informacionCerrar{
    
    display: inline-flex;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: inherit;
    font-weight: 600;
    padding: 11px 31px;
    height: 40px;
    color: rgb(0, 0, 0);
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    transition: background 0.15s ease 0s;
    background-color: #ffed00;
}
svg.css-4gyzyg.ub-w_16px.ub-h_16px.ub-box-szg_border-box > path{
    fill: #000000;
}
div.css-1y9310r.ub-w_32px.ub-h_32px.ub-dspl_flex.ub-algn-itms_center.ub-just-cnt_center.ub-box-szg_border-box{
    background-color: #ffed00;
    display: none;
}