.testimonial .tiles-item {
   
}
.baldosa-lg{
  flex-basis: 100% !important;
  max-width: 100% !important;
}
@media (min-width: 1048px) {
  .baldosa-md{
    flex-basis: 47% !important;
    max-width: 47% !important; 
  }
}
@media (max-width: 1047px) {
.baldosa-md{
  flex-basis: 100% !important;
  max-width: 100% !important; 
}
}
.micolo {
    background-image: linear-gradient(120deg, #ffee0000,#ffee0000 100%);
    background-repeat: no-repeat;
    background-size: 50% 1px;
    background-position: 50% 50%;
    transition: background-size 0.1s ease-in;
    color: white;
  }

  .micolo:hover {
    background-image: linear-gradient(120deg, #ffee00,#ffee00 100%);
    background-size: 100% 100% !important;
    color: black;
  }

  .testimonial-item-name.text-color-high{
    font-weight: 900;
  }

  .mititulo{
    background-color: rgba(255, 0, 0, 0);
    display: inline-flex;
    width: 80%;
    justify-content: center;
  }
  .mitexto{
    margin-bottom: 0px !important;
  }
  .miboton{
    margin-left: 16px;
    background-color: transparent !important;
    color: #ffee00 !important;
    border-color: #ffee00 !important;
    padding: 5px !important;
    align-items: center ;
    height: 40px !important;
  }
  .grow { transition: all .2s ease-in-out; }
  .grow:hover { transform: scale(1.25); }

  .growX { transition: all .2s ease-in-out; }
  .growX:hover { transform: scale(1.05, 1.05);  }

  .grow2 { transition: all .2s ease-in-out; }
  .grow2:hover { transform: scale(1.1, 1.15); }

